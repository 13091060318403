import { Link } from "react-router-dom";


const LanguageModels = () => {
    return (
    <>

        <div class="breadcrumb">
            <span class="caret">&gt;</span>
            <span class="link"><Link to="/capabilities">Capabilities</Link></span>
            <span class="caret">&gt;</span>
            <span class="current">Language Models</span>
        </div>



        <h2 class="featurette-heading">Large (and Small)<span class="text-muted"> Language Models. </span></h2>

        <p class="lead">
        <Link to="" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/story">Fine-Tuning</Link> 
        <Link to="" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/story">RAG</Link> 
        <Link to="" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/story">LoRA Adapters</Link> 
        <Link to="" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/learn">Integration</Link> 
        <Link to="" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/process">Model Design</Link> 
        <Link to="" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/culture">LLM Ops</Link> 
        </p>

        <div class="row featurette">
            <div class="col-md-8">
            <p class="lead">
                Empowering your company with innovative capabilities, our team excels in seamlessly integrating large or small language models 
                into your proprietary systems. This strategic implementation allows your teams to harness the power of advanced language 
                processing for content creation, recommendations, conversational AI, and pattern related use cases.
                </p>
                <p>
                    <ul>
                    <li>Fine-Tuning, LoRA Adapters and RAG for privatized LLM Applications</li>
                    <li>Testing Frameworks to test model performance and accuracy for production</li>
                    <li>Ops for deployment of your models</li>
                    <li>Vector databases and data pipeline design</li>
                    </ul>
                </p>
                <div>
                To discuss your project and how we can help &nbsp;
                <Link to="/contact" class="btn btn-sm btn-primary" href="/contact" onclick="load_page(this)" data-content="content/contact">Contact us</Link>

                </div>
    
            </div>
            <div class="col-md-4">
            <img src="/img/llm.png" alt="Language Models"  class="max-px-300 roundy" />
            </div>
        </div>



    </>
    )
  };
  
  export default LanguageModels;