import { Link } from "react-router-dom";

const JobsProjectCoordinator = () => {
    return (
    <>

    <div class="breadcrumb">
        <span class="caret">&gt;</span>
        <span class="link"><Link to="/jobs">Work Opportunities</Link></span>
        <span class="caret">&gt;</span>
        <span class="current">Project Coordinator</span>
    </div>

    <h1>Project Coordinator</h1>

    <div class="wpb_wrapper">
    <strong><font color="#2272FF">Position Summary:</font></strong> As a Project Coordinator at Saigon A.I., you will play a critical role in supporting the planning, execution, and monitoring of both product and project development. You will collaborate closely with developers, data scientists and project managers to ensure project objectives are met on time and within scope. This role requires strong organizational skills, attention to detail, and the ability to facilitate communication among team members, the COO and CEO, and customers.
 <p></p>
 <strong><font color="#2272FF">Key Responsibilities:</font></strong><p></p>
    <ul>
        <li><strong><font color="#2272FF">Project Planning:</font></strong> Collaborate with all stakeholders to define project scope, objectives, and deliverables. Develop project plans, timelines, and resource allocation to ensure efficient project execution.</li>
        <li><strong><font color="#2272FF">Documentation:</font></strong> Maintain comprehensive project documentation, including project plans, status reports, meeting minutes, and project deliverables. Ensure documentation is organized and accessible to team members.</li>
        <li><strong><font color="#2272FF">Resource Coordination:</font></strong> Work closely with developers and project managers to allocate and coordinate resources, including data, tools, and personnel, to support project activities with updated timelines and project completion estimates.</li>
        <li><strong><font color="#2272FF">Communication:</font></strong> Facilitate effective communication among project team members, customers, and bosses. Provide regular updates on project progress, issues, and risks. Escalate critical issues to project managers promptly.</li>
        <li><strong><font color="#2272FF">Quality Assurance:</font></strong> Ensure that project deliverables meet established quality standards. Conduct reviews and quality checks as needed to maintain project integrity.</li>
        <li><strong><font color="#2272FF">Timeline Management:</font></strong> Monitor project timelines and milestones to ensure adherence to schedules. Proactively identify potential delays and work with the team to mitigate them.</li>
        <li><strong><font color="#2272FF">Customer Engagement:</font></strong> Establish positive relationships with customers, including business leaders and technical teams, to ensure alignment with project objectives and outcomes. </li>
    </ul>
<p></p>
<strong><font color="#2272FF">Qualifications:</font></strong><p></p>
{/*
    <ul>
        <li>Bachelor's degree in a related area of business (e.g., Business Administration, Project Management or similar).</li>
        <li>At least 1 year experience in project coordination or related roles.</li>
        <li>Strong organizational skills with a high attention to detail.</li>
        <li>Excellent communication and interpersonal skills in English and Vietnamese.</li>
        <li>Familiarity with data science concepts and tools is a plus.</li>
        <li>Ability to work collaboratively in a team environment.</li>
    </ul>
    */}
    <ul>
        <li>Bachelor's degree in a related area of business (e.g., Business Administration, Project Management or similar).</li>
        <li>At least 1 year experience in project coordination or related roles.</li>
        <li>Strong organizational skills with a high attention to detail.</li>
        <li>Excellent communication and interpersonal skills in English and Vietnamese.</li>
        <li>Ability to work collaboratively in a team environment.</li>
        <li>Experience with Agile methodology is a plus.</li>
    </ul>

 <p></p>
{/* <strong><font color="#2272FF">Benefits:</font></strong><p></p>
    <ul>
        <li>Salary will be discussed based on candidates' experience and ability.</li>
        <li>Work in a young, dynamic international company founded by experienced American entrepreneurs.</li>
        <li>Health insurance, Public holiday and Annual leave as per Vietnamese Labor law.</li>
        <li>Opportunity for growth and training.</li>
        <li>Experience an international business environment, which provides opportunities to improve Business English.</li>
        <li>Company Retreat once a year for full-time employees.</li>
        <li>Experience the unique harmony of Vietnamese - American Culture.</li>
        <li>Tea-times, Coffee Talk, I.T. English Club, sports activities, in-house games, etc.</li>
        <li>Personal coaching and career orientation.</li>
    </ul> */}
<p></p>
    <ul>
        <li>Salary will be discussed based on candidates' experience and ability. 100% salary during probation.</li>
        <li>Collaborative and innovative work environment.</li>
        <li>Opportunity for growth and training. </li>
        <li>Opportunities to work with global customers from different cultures.</li>
        <li>Experience an international business environment, which provides opportunities to improve Business English.</li>
        <li>Company Retreat once a year for full-time employees.</li>
        <li>Experience the unique harmony of Vietnamese - American Culture.</li>
        <li>Tea-times, Coffee Talk, I.T. English Club, sports activities, in-house games, etc.</li>
        <li>Working time: Monday - Friday, 8:30AM - 6:00PM.</li>
        <li>Annual Salary review and performance bonus.</li>
        <li>12 days annual leave per year.</li>
        <li>Company-provided MacOS computer, iPad, camera, and earbuds for working at the office.</li>
        <li>Insurance provided in full compliance with the Vietnam Labor Code.</li>
    </ul>

<div class="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
<span class="vc_sep_holder vc_sep_holder_l">
    <span class="vc_sep_line"></span>
</span>
<span class="vc_sep_holder vc_sep_holder_r">
    <span class="vc_sep_line"></span>
</span>
</div>
</div>

<Link to="/jobs/process" class="btn btn-warning">How To Apply</Link>


    </>
    )
  };
  
  export default JobsProjectCoordinator;